/* Resume Section */
#resume {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.7);
    max-width: 900px; 
    margin: 100px auto 60px; 
    border-radius: 15px;
  }
  
  .resume{
    text-align: center; 
    margin-top: 20px;
  }
  
  .resume img {
    max-width: 100%; 
    width: 90%;     
    height: auto;   
    display: block;
    margin: 0 auto; 
  }
  
  .centered {
    text-align: center;
    margin-top: 20px;
  }
  