/* General Styles for Restaurant Recommender */
#restaurant-recommender {
    padding: 20px;
    background-color: #f9f9f9a1;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
}

#restaurant-recommender h2 {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

form {
    max-width: 500px;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

input[type="text"], input[type="number"], input[type="email"] {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

button {
    background-color: #f48fb1;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 30px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: block;
    margin: 20px auto;
}

/* Photo Gallery */
.photo-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; /* Spacing between images */
    margin-top: 10px;
  }
  
  .gallery-item {
    width: 150px; /* Set the width of the square */
    height: 150px; /* Set the height of the square */
    overflow: hidden; /* Hide any overflowed part of the image */
    position: relative;
    border-radius: 10px; 
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    display: block;
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover img {
    transform: scale(1.05); 
  }

  .centered-texts {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
  }
  
  