/* Contact Section */
#contact {
    background-color: rgba(255, 255, 255, 0.7); 
    padding: 40px; 
    margin: 100px auto 60px; 
    border-radius: 15px; 
    max-width: 900px; 
    text-align: center; 
  }
  
