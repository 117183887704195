.map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;  
    max-width: 1200px;
    height: 600px;
    padding: 10px;
  }
  
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px; 
  }
  