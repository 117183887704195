/* About Section */
#about {
    background-color: rgba(255, 255, 255, 0.7); 
    padding: 20px;
    margin: 100px auto 60px; 
    border-radius: 15px;
    max-width: 900px;
  }
  
  /* About Container */
  .about-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  /* About Text */
  .about-text {
    flex: 1;
    margin-right: auto; 
  }
  
  /* About Image */
  .about-image {
    flex: 0 0 400px;
    margin-left: auto;
  }
  
  .about-image img {
    max-width: 400px;
    max-height: 400px; 
    width: auto; 
    height: auto; 
    object-fit: cover;
    margin-left: 100px;
  }
  